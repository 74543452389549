import { GeneralErrorBoundary } from "#app/components/error-boundary.js"
import { ActionFunctionArgs, json, LoaderFunctionArgs, MetaFunction, redirect } from "@remix-run/node"
import LogoBackground from '#app/assets/logo-fundo.svg?react';
import LogoHeader from '#app/assets/logo-colmeia-horizontal.svg?react';
import { Field, FieldError } from "#app/components/Field.js";
import { Label } from "#app/components/ui/label.js";
import { InputConform } from "#app/components/conform/Input.js";
import { z } from "#app/utils/zod";
import { parseWithZod } from "@conform-to/zod";
import { useForm } from "@conform-to/react";
import { Form, useActionData, useSearchParams } from "@remix-run/react";
import { HoneypotInputs } from "remix-utils/honeypot/react";
import { Button } from "#app/components/ui/button.js";
import { login, requireAnonymous, handleNewSession } from "#app/utils/auth.server.js";
import { validateCSRF } from "#app/utils/csrf.server.js";
import { checkHoneypot } from "#app/utils/honeypot.server.js";
import { AuthenticityTokenInput } from 'remix-utils/csrf/react'




const LoginSchema = z.object({
	username: z.string(),
	password: z.string(),
	redirectTo: z.string().optional(),
	remember: z.boolean().optional(),
  });

  export async function loader({ request }: LoaderFunctionArgs) {
	await requireAnonymous(request)
	return json({})
}

  export async function action({ request }: ActionFunctionArgs) {
	await requireAnonymous(request)
	const formData = await request.formData()
	await validateCSRF(formData, request.headers)
	checkHoneypot(formData)
	const submission = await parseWithZod(formData, {
		schema: intent =>
			LoginSchema.transform(async (data, ctx) => {
				const session = await login(data);

				if (!session) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: 'Usuário ou senha errados',
					})
					return z.NEVER
				}

				return { ...data, session }
			}),
		async: true,
	})
	// get the password off the payload that's sent back
	delete submission.payload.password

	if(submission.status !== 'success') {
		return json(submission.reply(), {
			// You can also use the status to determine the HTTP status code
			status: submission.status === 'error' ? 400 : 200,
		  });
	}


	const { session, remember, redirectTo } = submission.value

	return handleNewSession({
		request,
		session,
		remember: remember ?? false,
		redirectTo,
	})
}

  

export default function LoginPage() {
	const actionData = useActionData<typeof action>()
	const [searchParams] = useSearchParams()
	const redirectTo = searchParams.get('redirectTo') ?? '/admin'
	const [form, fields] = useForm({
		id: 'signup',
		onValidate({ formData }) {
		  return parseWithZod(formData, { schema: LoginSchema });
		},
		defaultValue: {redirectTo},
		shouldRevalidate: 'onInput',
	  });

    return <div className={`relative bg-gradient-to-br from-brand-secondary to-brand h-dvh overflow-hidden p-6 py-8`}>
        <LogoHeader />
        <LogoBackground className="pointer-events-none absolute right-0 bottom-0 translate-y-[0%] translate-x-[50%]" />
        <h1 className="mt-12 mb-8 scroll-m-20 text-3xl text-white font-bold lg:text-5xl">
            Entrar na sua conta agora
        </h1>
        <Form method="POST" id={form.id}
        	onSubmit={form.onSubmit}
            className="grid gap-4"
		>
			<AuthenticityTokenInput />
			<HoneypotInputs />

            <Field>
				<Label variant='dark' htmlFor={fields.username.id}>Usuário ou email</Label>
				<InputConform meta={fields.username} type="text" variant="dark" />
				{fields.username.errors && <FieldError>{fields.username.errors}</FieldError>}
			</Field>
            <Field>
				<Label variant='dark' htmlFor={fields.password.id}>Senha</Label>
				<InputConform variant='dark' meta={fields.password} type="password" />
				{fields.password.errors && <FieldError>{fields.password.errors}</FieldError>}
				{/* @ts-expect-error */}
				<FieldError>{actionData?.error ? actionData?.error[''][0]: null}</FieldError>
			</Field>
			<InputConform meta={fields.redirectTo} type='hidden' />
			<div className="w-full grid gap-4  absolute bottom-0 left-0 pl-4 pr-4 pb-8">
				<Button width='full' size={'lg'} variant={'brandAccent'}>Entrar</Button>
				{/* <a href="/forgot-password" className="text-white underline text-center">Esqueci minha senha</a> */}
			</div>
        </Form>
        

    </div>
}


export const meta: MetaFunction = () => {
	return [{ title: 'Colmeia PRO | Entrar' }]
}

export function ErrorBoundary() {
	return <GeneralErrorBoundary />
}
